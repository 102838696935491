import { StorageService } from './services/storage/storage.service';
import { SmartAudioService } from './services/smart-audio/smart-audio.service';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { UserService } from 'app/core/user/user.service';
import { Component } from '@angular/core';
import { AuthService } from './core/auth/auth.service';
import { Platform } from '@ionic/angular';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent
{

    constructor(
        private _authService: AuthService, 
        private _userService: UserService, 
        private _navigationService: NavigationService, 
        private platform: Platform, 
        private smartAudioService: SmartAudioService,
        private storageService: StorageService)
    {
        this.storageService.init();
        this.smartAudioService.preload('beep-ok', 'assets/audio/beep-ok.wav');
        this.smartAudioService.preload('beep-wrong', 'assets/audio/beep-wrong.wav');
    }

    ngOnInit() {
        this.storageService.get('STORAGE_TEMP_RESET').then((value) => {
            if (!value) {
                alert('Attenzione, la sessione precedente verrà disconnessa a causa di un\'aggiornamento.');

                this.storageService.reset();

                localStorage.clear();
        
                this._authService.signOut();
                
                this.storageService.set('STORAGE_TEMP_RESET', true);
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => this._userService.load());
    }
}
