import { NavigationService } from 'app/core/navigation/navigation.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { User } from 'app/core/user/user.types';
import { environment } from 'environments/environment';
import { StorageService } from 'app/services/storage/storage.service';

@Injectable({
    providedIn: 'root'
})
export class UserService
{
    private _storageKey: string = 'USER';
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    constructor(
        private _httpClient: HttpClient,
        private _storage: StorageService)
    {
    }

    set user(value: User)
    {
        this._storage.set(this._storageKey, value);
        this._user.next(value);
    }

    get user$(): Observable<User>
    {
        return this._user.asObservable();
    }

    get(id): Observable<User>
    {
        return this._httpClient.get<User>(environment.server + '/api/user/' + id).pipe(
            tap((user) => {
                this.user = user;
            })
        );
    }

    update(user: User): Observable<any>
    {
        return this._httpClient.patch<User>(environment.server + '/api/user/create', {user}).pipe(
            map((response) => {
                this.user = response;
            })
        );
    }

    create(user: any) {
      return this._httpClient.post(environment.server + '/api/user/create', user);
    }

    load() {
        this._storage.get('USER').then((user) => {
            this.user = user;
        }).catch((error) => {
            this.user = undefined;
        });
    }

    getSupervisors(){
        return this._httpClient.get<Array<User>>(environment.server + '/api/user/supervisors/all')
    }

    logSupervisor(operation: string, message: string, requestUserID: number, requestUsername: string, SupervisorUserID: number, SupervisorUsername: string) {
        let request = { operation, message, requestUserID, requestUsername, SupervisorUserID, SupervisorUsername }
        return this._httpClient.post(environment.server + '/api/user/supervisors/log', request)
    }
}
